export default function Skills() {
  return <div></div>;
}

function SkillItem() {
  return (
    <div>
      <p>Title</p>
    </div>
  );
}
 